import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <h1>The Workshop</h1>
    <p>The Workshop is a solo software studio by <a href="https://github.com/non-binary">@non-binary</a>.</p>
    <ul>
      <li><a href="mailto:luna.g@protonmail.com">Contact</a></li>
      <li><a href="https://github.com/the-workshop">GitHub</a></li>
    </ul>
    <h2>Projects</h2>
    <p>TBD!</p>
  </Layout>
)

export default IndexPage
